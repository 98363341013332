<template>
	<div class="block-inner">
		<RichEditor v-slot="{ editor }" v-model="block.content" :block-id="block.id" :extensions="extensions" @input="save" @click.native="showModal">
			<button @click="editor.chain().focus().addOptions().run()">
				Op
			</button>
		</RichEditor>

		<modal :name="optionsModalName" height="auto" :adaptive="true" :focus-trap="true" @before-close="save">
			<h2>Open-cloze options</h2>
			<div class="modal-content">
				<template v-if="currentOptions && currentOptions.length">
					<draggable tag="ol" :list="currentOptions" :group="{ name: 'options' , pull: false, put: false }">
						<li v-for="(option, index) in currentOptions" :key="option.id" :data-id="option.id" :data-correct="option.isCorrect">
							<BoolInput v-if="!freetextAnswers" v-model="option.isCorrect" :label="option.label" :checked="option.isCorrect" />
							<span v-else>{{ option.label }}</span>
							<IconCross :width="12" :height="12" @click.native="removeOption(index)" />
						</li>
					</draggable>
				</template>
				<p v-else>
					No options yet.
				</p>
				<input v-model="newOption" type="text" :placeholder="freetextAnswers ? 'Add correct answer...' : 'Add new option...'" />
				<input v-model="currentOptionGroup" type="hidden" />
			</div>
			<div class="modal-footer">
				<BaseButton type="secondary" @click="$modal.hide(optionsModalName)">
					Close
				</BaseButton>
				<BaseButton type="primary" @click="addOption">
					Add
				</BaseButton>
			</div>
		</modal>
	</div>
</template>

<script>
	import Options from '../../inc/editor-extensions/options';
	import BaseButton from '../BaseButton';
	import {v4} from 'uuid';
	import BoolInput from '../BoolInput';
	import draggable from 'vuedraggable';
	import IconCross from '../icons/IconCross';
	import RichEditor from '../RichEditor';

	export default {
		name: 'EditorBlockOpenCloze',
		components: {
			BaseButton,
			BoolInput,
			draggable,
			IconCross,
			RichEditor
		},
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				newOption: '',
				currentOptionGroup: ''
			};
		},
		computed: {
			currentOptions() {
				return this.block.options[this.currentOptionGroup] || [];
			},
			freetextAnswers() {
				return this.block.meta.freetextAnswers;
			},
			optionsModalName() {
				return 'options-modal-' + this.block.id;
			},
			extensions() {
				return [Options];
			}
		},
		created() {
			if(!this.block.options) {
				this.$set(this.block, 'options', {});
			}

			if(!this.block.meta.sortOptions) {
				this.$set(this.block.meta, 'sortOptions', 'manual');
			}

			if(!this.block.meta.freetextAnswers) {
				this.$set(this.block.meta, 'freetextAnswers', false);
			}
		},
		methods: {
			showModal(event) {
				if(event.target.classList.contains('options')) {
					this.currentOptionGroup = event.target.dataset.id;

					if(typeof this.block.options[this.currentOptionGroup] === 'undefined') {
						this.block.options[this.currentOptionGroup] = [];
					}

					this.$modal.show(this.optionsModalName);
				}
			},
			addOption() {
				if(!this.newOption.length) {
					return;
				}

				const option = {
					id: v4(),
					label: this.newOption,
					isCorrect: this.freetextAnswers
				};

				this.currentOptions.push(option);
				this.newOption = '';
			},
			save() {
				const optionGroups = this.getOptionGroupsFromContent(this.block.content.content, []);
				const diff = Object.keys(this.block.options).filter(g => !optionGroups.includes(g));

				for(const key of diff) {
					Reflect.deleteProperty(this.block.options, key);
				}

				this.$emit('edit');
			},
			getOptionGroupsFromContent(content, options) {
				for(const node of content) {
					if(node.type === 'options') {
						options.push(node.attrs['data-id']);
					}
					else if(node.content && node.content.length) {
						this.getOptionGroupsFromContent(node.content, options);
					}
				}

				return options;
			},
			removeOption(index) {
				this.currentOptions.splice(index, 1);
				this.$emit('edit');
			}
		}
	};
</script>

<style lang="scss" scoped>
	$options_line_padding: 4px 25px 6px 10px;

	.vm--modal > .bool-input::v-deep {
		margin-bottom: $default_padding * 1.5;
	}

	.block-inner::v-deep {
		padding: 0 !important;

		.format-bar {
			left: $site__padding * 2;
		}

		.ProseMirror {
			min-height: $site__padding * 4;
			padding: $site__padding $site__padding * 2;
			font-size: 1em;
			line-height: $line__height;
			background-color: transparent;
			transition: background-color 0.2s ease-out;
			border-radius: 0;
		}

		.ProseMirror-focused {
			background-color: $color__white;
			box-shadow: none;
		}

		.options {
			position: relative;
			cursor: pointer;
			background-color: $color__white;
			padding: $options_line_padding;
			margin: 0 3px;
			border-top-left-radius: $border_radius;
			border-top-right-radius: $border_radius;
			box-shadow: $box_shadow__input;
			min-width: 150px;
			display: inline-block;

			&::after {
				content: "";
				position: absolute;
				top: 50%;
				right: 8px;
				border: 5px solid transparent;
				border-top-color: $color__dark;
				transform: translateY(-15%);
			}
		}
	}

	ol {
		list-style-type: none;
		margin-left: 0;
		padding-left: 0;

		li {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			padding: 5px;
			border-radius: $border_radius;

			&:nth-child(odd) {
				background-color: $color__gray;
			}

			.bool-input {
				flex: 1;
				margin: 0;
			}

			& > svg {
				cursor: pointer;
				padding: 9px;
				box-sizing: content-box;
				margin: -5px;
			}
		}

		li,
		.bool-input::v-deep {
			cursor: grab;

			.input {
				cursor: pointer;
			}

			&:active {
				cursor: grabbing;
			}
		}
	}

	.ProseMirror-focused {
		.options {
			background-color: $color__gray;
		}
	}
</style>