import {Node, mergeAttributes, VueNodeViewRenderer} from '@tiptap/vue-2';
import OptionsTemplate from './OptionsTemplate.vue';
import {v4} from 'uuid';

const OptionsNode = Node.create({
	name: 'options',
	group: 'inline',
	inline: true,
	selectable: false,
	addNodeView() {
		// eslint-disable-next-line new-cap
		return VueNodeViewRenderer(OptionsTemplate);
	},
	parseHTML() {
		return [
			{
				tag: 'span',
				getAttrs: dom => ({
					class: dom.getAttribute('class'),
					'data-id': dom.getAttribute('data-id')
				})
			}
		];
	},
	renderHTML({HTMLAttributes}) {
		return [
			'span',
			mergeAttributes(HTMLAttributes)
		];
	},
	addAttributes() {
		return {
			class: {
				default: 'options'
			},
			'data-id': {
				default: null
			}
		};
	},
	addCommands() {
		return {
			addOptions: () => ({state, dispatch}) => {
				const {selection, tr} = state;
				const position = selection.$cursor
					? selection.$cursor.pos
					: selection.$to.pos;

				const node = this.type.create({
					'data-id': v4()
				});

				const transaction = tr.insert(position, node);

				dispatch(transaction);
			}
		};
	}
});

export default OptionsNode;