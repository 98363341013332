<template>
	<NodeViewWrapper as="span">
		<span :class="node.type.name" :data-id="node.attrs['data-id']">-- choose --</span>
	</NodeViewWrapper>
</template>

<script>
	import {NodeViewWrapper} from '@tiptap/vue-2';

	export default {
		components: {
			NodeViewWrapper
		},
		props: {
			node: Object,
			updateAttributes: Function
		},
		methods: {
			onChange() {
				this.updateAttributes({
					correct: !this.node.attrs.correct
				});
			}
		}
	};
</script>